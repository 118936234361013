<template>
    <v-layout>
        <v-text-field
            v-model="value"
            type="text"
        />

        <v-tooltip bottom>
            <template #activator="{ on }">
                <v-icon
                    class="mr-3"
                    v-on="on"
                    @click="confirm"
                >
                    mdi-check
                </v-icon>
            </template>
            <span class="text-white">Confirm</span>
        </v-tooltip>

        <v-tooltip bottom>
            <template #activator="{ on }">
                <v-icon
                    v-on="on"
                    @click="cancel"
                >
                    mdi-close
                </v-icon>
            </template>
            <span class="text-white">Cancel</span>
        </v-tooltip>
    </v-layout>
</template>

<script>
export default {
    name: 'ScopeInput',
    props: {
        initialValue: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            value: ''
        };
    },

    created() {
        this.value = this.initialValue;
    },

    methods: {
        confirm() {
            this.$emit('confirm', this.value);
        },

        cancel() {
            this.value = '';
            this.$emit('cancel');
        }
    }
};
</script>

<style scoped>

</style>
