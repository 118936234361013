<template>
    <v-toolbar
        id="core-toolbar"
        flat
        prominent
    >
        <div class="v-toolbar-title">
            <v-toolbar-title
                class="tertiary--text font-weight-bold"
            >
                <v-btn
                    v-if="responsive"
                    class="default v-btn--simple"
                    dark
                    icon
                    @click.stop="onClickBtn"
                >
                    <v-icon>mdi-view-list</v-icon>
                </v-btn>
                {{ title }}
            </v-toolbar-title>
            <span
                class="font-weight-thin toolbar-description"
                v-html="description"
            />
        </div>

        <v-spacer />
        <v-toolbar-items>
            <v-flex
                align-center
                layout
                py-2
            >
                <v-menu
                    bottom
                    left
                    content-class="dropdown-menu"
                    offset-y
                    transition="slide-y-transition"
                />
                <v-tooltip
                    bottom
                >
                    <template #activator="{ on }">
                        <v-icon
                            color="tertiary"
                            v-on="on"
                            @click="logout"
                        >
                            mdi-logout
                        </v-icon>
                    </template>
                    <span class="text-white">Logout</span>
                </v-tooltip>
            </v-flex>
        </v-toolbar-items>
    </v-toolbar>
</template>

<script>

import {
    mapMutations
} from 'vuex';

export default {
    data: () => ({
        title: null,
        description: null,
        responsive: false,
        responsiveInput: false,
        on: null
    }),

    watch: {
        '$route'(val) {
            this.title = val.name;
            this.description = val.meta.description;
        }
    },

    mounted() {
        this.title = this.$route.name;
        this.description = this.$route.meta.description;
        this.onResponsiveInverted();
        window.addEventListener('resize', this.onResponsiveInverted);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResponsiveInverted);
    },

    methods: {
        ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
        ...mapMutations('user', ['unsetAccessToken']),
        onClickBtn() {
            this.setDrawer(!this.$store.state.app.drawer);
        },
        onClick() {
            //
        },
        onResponsiveInverted() {
            if (window.innerWidth < 991) {
                this.responsive = true;
                this.responsiveInput = false;
            } else {
                this.responsive = false;
                this.responsiveInput = true;
            }
        },

        logout() {
            this.unsetAccessToken();
            this.$router.replace('/login');
        }
    }
};
</script>

<style lang="scss" scoped>

.v-icon{
    cursor: pointer
}

#core-toolbar a {
    text-decoration: none;
}
</style>
