<template>
    <v-layout
        wrap
    >
        <v-flex
            v-for="module in pagedModules"
            :key="module.name"
            class="mb-5"
            xs12
            sm3
        >
            <v-layout
                class="modules-list__module-layout"
                column
            >
                <div
                    class="modules-list__module-thumbnail"
                    @click="selected(module.name)"
                >
                    <v-chip
                        v-if="module.installed && module.upgradeAvailable"
                        class="modules-list__module-installed"
                        color="success"
                        text-color="white"
                    >
                        Update available (v{{ module.upgradeAvailableVersion }})
                    </v-chip>
                    <v-chip
                        v-else-if="module.installed"
                        class="modules-list__module-installed"
                        color="tertiary"
                        text-color="white"
                    >
                        Installed (v{{ module.bundleVersion }})
                    </v-chip>
                    <img
                        :src="module.icon"
                        class="modules-list__module-icon"
                        alt=""
                    >
                </div>

                <div
                    class="modules-list__module-label"
                    v-text="module.label"
                />
            </v-layout>
        </v-flex>

        <v-flex xs12>
            <v-layout class="modules-list__pagination">
                <v-pagination
                    v-if="modules.length"
                    v-model="paginatorData.page"
                    :length="paginator.total"
                    :total-visible="paginator.visible"
                    justify-center
                />
                <span v-if="!modules.length">
                    No connectors found
                </span>
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: 'ModulesList',

    props: {
        modules: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            paginatorData: {
                page: 1
            }
        };
    },

    computed: {
        paginator() {
            return {
                page: this.paginatorData.page,
                total: Math.ceil(this.modules.length / 20),
                visible: 6
            };
        },

        pagedModules() {
            const { page } = this.paginator;
            const perPage = 20;
            const start = perPage * (page - 1);
            const end = perPage * page;
            return this.modules.slice(start, end);
        }
    },

    watch: {
        modules() {
            this.paginatorData.page = 1;
        }
    },

    methods: {
        selected(name) {
            this.$emit('selected', name);
        }
    }
};
</script>

<style lang="scss" scoped>
.modules-list {
    &__module-layout {
        padding: 20px;
        align-items: center;
    }
    &__module-thumbnail {
        position: relative;
        display: flex;
        flex: 0 0 146px;
        align-items: center;
        justify-content: center;
        width: 146px;
        height: 146px;
        border: solid 1px #e8e8e8;
        border-radius: 4px;
        background: white;
        cursor: pointer;
    }
    &__module-installed {
        position: absolute;
        top: -45px;
    }
    &__module-icon {
        display: block;
        max-height: 46px;
        max-width: 46px;
    }
    &__module-label {
        max-width: 100%;
        margin-top: 0.6em;
        text-align: center;
        word-wrap: break-word;
        font: 15px 'nunitosans-semibold';
        color: #222;
    }
    &__pagination {
        justify-content: center;
    }
}
.v-chip {
    border-radius: 4px;
}
</style>
