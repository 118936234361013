<template>
    <!-- eslint-disable -->
    <v-dialog
        :value="show"
        class="am-service-dialog"
        width="500"
    >
        <v-card>
            <v-card-title class="am-update-acl-dialog__title">
                Create new configuration
            </v-card-title>

            <v-card-text>
                Configuration ID:
                <v-text-field
                    v-model="$v.form.serviceId.$model"
                    class="mb-3"
                    type="text"
                />
                <div
                    class="tooltip"
                >
                    Configuration ID has the pattern <code>[vendor]:[service]</code>. For example, appmixer:slack. Notice the ":" character used as a separator instead of the "." that is used in the connector name. The Appmixer Engine uses this configuration ID to match for the connector to apply the configuration to.
                </div>
                <div
                    v-if="formSubmitted && !$v.form.serviceId.required"
                    class="invalid-feedback"
                >
                    Configuration ID is required
                </div>
                <div
                    v-if="formSubmitted && errors.isUnique"
                    class="invalid-feedback"
                >
                    The Configuration ID already exists
                </div>
            </v-card-text>

            <v-card-actions class="am-update-acl-dialog__buttons">
                <v-btn
                    :disabled="formIsInvalid"
                    color="primary"
                    text
                    @click.stop="confirm"
                >
                    Save
                </v-btn>
                <v-btn
                    color="grey"
                    text
                    @click.stop="cancel"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import AuthConfigRequests from '../../utils/requests/authConfig';
export default {
    name: 'NewServiceDialog',
    validations: {
        form: {
            serviceId: {
                required
            }
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            formSubmitted: false,
            form: {
                serviceId: null
            },
            errors: {
                isUnique: false
            }
        };
    },

    computed: {
        formIsInvalid() {
            return this.formSubmitted && (this.$v.$invalid || this.errors.isUnique);
        }
    },

    watch: {
        form: {
            deep: true,
            handler() {
                this.formSubmitted = false;
                this.errors.isUnique = false;
            }
        }
    },

    methods: {
        async isUnique(value) {
            if (!value) {
                return true;
            }
            const result = await AuthConfigRequests.getAuthConfig(value);
            return Object.keys(result).length === 0;
        },

        async confirm() {
            this.formSubmitted = true;
            const isUnique = await this.isUnique(this.form.serviceId);
            if (!isUnique) {
                this.errors.isUnique = true;
            } else if (!this.$v.$invalid) {
                this.$emit('create', this.form.serviceId);
                this.form.serviceId = null;
            }
        },

        cancel() {
            this.form.serviceId = null;
            this.$emit('cancel');
        }
    }
};
</script>

<style lang="scss" scoped>
  .am-service-dialog {
    &__title {
      font-weight: bold;
    }
  }

  .invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .tooltip {
    width: 100%;
    margin-top: .25rem;
    font-size: 110%;
    color: #3375F6;
  }
</style>
