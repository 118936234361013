<template>
    <v-app>
        <v-content>
            <spinner />
            <v-container
                fill-height
            >
                <v-layout
                    align-center
                    justify-center
                >
                    <v-flex md8>
                        <h2 class="text-xs-center text-primary">
                            <img src="/img/appmixer-logo.svg">
                            <br>Backoffice
                            <br><span class="subheading">Sign-in</span>
                        </h2>

                        <v-form
                            ref="form"
                            v-model="valid"
                            :lazy-validation="false"
                            class="mt-5"
                        >
                            <v-text-field
                                v-model="username"
                                :rules="usernameRules"
                                label="Username"
                                required
                            />

                            <v-text-field
                                v-model="password"
                                :rules="passwordRules"
                                type="password"
                                label="Password"
                                required
                            />

                            <div class="text-danger">
                                {{ authError }}
                            </div>

                            <v-btn
                                :disabled="!valid"
                                color="primary"
                                class="mr-4"
                                @click="authenticate"
                            >
                                Sign-in
                            </v-btn>
                        </v-form>
                    </v-flex>
                </v-layout>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Spinner from '../components/common/Spinner';

export default {
    name: 'Login',
    metaInfo() {
        return {
            title: 'Appmixer Backoffice - Sign in'
        };
    },
    components: { Spinner },
    data() {
        return {
            valid: true,
            username: '',
            usernameRules: [
                v => !!v || 'Username is required'
            ],
            password: '',
            passwordRules: [
                v => !!v || 'Password is required'
            ]
        };
    },
    computed: {
        ...mapGetters('user', {
            accessToken: 'accessToken',
            authError: 'error'
        })
    },
    async beforeCreate() {
        if (this.accessToken) {
            this.$router.replace('/dashboard/modules');
        }
    },
    methods: {
        ...mapActions('user', {
            authenticateUser: 'authenticate'
        }),

        async authenticate() {
            if (!this.$refs.form.validate()) {
                return;
            }
            await this.authenticateUser({ username: this.username, password: this.password });
            if (this.accessToken) {
                this.$router.replace('/dashboard/modules');
            }
        }

    }
};
</script>

<style scoped>

</style>
