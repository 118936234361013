<template>
    <vuetify-alert
        class="alert"
        :value="value"
        :type="type"
        :dismissible="dismissible"
    >
        <template v-if="message">
            {{ message }}
        </template>

        <template v-else>
            <slot />
        </template>
    </vuetify-alert>
</template>

<script>
import VAlert from 'vuetify/lib/components/VAlert';

export default {
    name: 'VAlert',

    components: {
        VuetifyAlert: VAlert
    },

    props: {
        value: {
            type: Boolean,
            required: true
        },

        type: {
            type: String,
            default: 'success'
        },

        dismissible: {
            type: Boolean,
            default: true
        },

        message: {
            type: String,
            default: null
        }
    }
};
</script>

<style scoped>
    .alert {
        z-index: 1;
    }
</style>
