export default {
    primary: '#2a64f6',
    secondary: '#4caf50',
    tertiary: '#1c1c1c',
    accent: '#82B1FF',
    error: '#f55a4e',
    info: '#00d3ee',
    success: '#5cb860',
    warning: '#c9954d'
};
