<template>
    <v-layout column>
        <div
            v-if="scopes.length === 0 && !addScopeInput"
            class="pa-3"
        >
            No scopes set
        </div>
        <div v-else>
            <v-list>
                <v-list-tile
                    v-for="(scope, idx) in scopes"
                    :key="idx"
                >
                    <template v-if="idx === editScopeInput">
                        <scope-input
                            :initial-value="scope"
                            @confirm="(value) => updateScope(idx, value)"
                            @cancel="editScopeInput = null"
                        />
                    </template>
                    <template v-else>
                        <v-list-tile-content>{{ scope }}</v-list-tile-content>

                        <v-list-tile-action>
                            <v-layout>
                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            class="mr-3"
                                            v-on="on"
                                            @click="editScope(idx)"
                                        >
                                            mdi-pencil
                                        </v-icon>
                                    </template>
                                    <span class="text-white">Edit</span>
                                </v-tooltip>

                                <v-tooltip bottom>
                                    <template #activator="{ on }">
                                        <v-icon
                                            v-on="on"
                                            @click="removeScope(idx)"
                                        >
                                            mdi-delete
                                        </v-icon>
                                    </template>
                                    <span class="text-white">Delete</span>
                                </v-tooltip>
                            </v-layout>
                        </v-list-tile-action>
                    </template>
                </v-list-tile>
            </v-list>
        </div>
        <v-layout v-if="addScopeInput">
            <scope-input
                @confirm="addScope"
                @cancel="addScopeInput = false"
            />
        </v-layout>
        <div v-else>
            <v-btn
                small
                color="primary"
                text
                @click="addScopeInput = true"
            >
                Add
            </v-btn>
        </div>
    </v-layout>
</template>

<script>
import ScopeInput from './ScopeInput';
export default {
    name: 'EditUserScopes',
    components: { ScopeInput },
    props: {
        scopes: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            editScopeInput: null,
            addScopeInput: false
        };
    },
    methods: {
        addScope(scope) {
            this.addScopeInput = false;
            this.$emit('addScope', scope);
        },

        editScope(index) {
            this.editScopeInput = index;
        },

        updateScope(index, scope) {
            this.editScopeInput = null;
            this.$emit('updateScope', index, scope);
        },

        removeScope(index) {
            this.$emit('removeScope', index);
        }
    }
};
</script>

<style scoped>

</style>
