/*!

=========================================================
* Vuetify Material Dashboard - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vuetify-material-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial
portions of the Software.

*/

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import Vuelidate from 'vuelidate';

// Global Vue mixins
import './mixins/global';

// Plugins
import './plugins';

// Components
import './components';

// Sync router with store
import { sync } from 'vuex-router-sync';

// Application imports
import App from './App';
import i18n from '@/i18n';
import router from '@/router';
import store from '@/store';
import VueSanitize from 'vue-sanitize-directive';

import { VOverlay } from './components/common/VOverlay';
import { VDataTable } from './components/common/VDataTable';
import { VDialog } from './components/common/VDialog';

// Sync store with router
sync(store, router);

Vue.component('VOverlay', VOverlay);
Vue.component('VDataTable', VDataTable);
Vue.component('VDialog', VDialog);

Vue.use(Vuelidate);
Vue.use(VueSanitize);

Vue.config.productionTip = false;

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app');
