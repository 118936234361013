import AuthConfigRequests from '@/utils/requests/authConfig';

export default {

    async validateConfig(module) {
        const requestBundleId = module.replace(/\./g, ':');

        return AuthConfigRequests.validateConfig(requestBundleId);
    }
};
