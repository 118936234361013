<template>
    <v-dialog
        :value="show"
        class="am-edit-user-dialog"
        width="500"
        @input="($event) => $emit('updateShow', $event)"
    >
        <v-card>
            <v-card-title class="am-edit-user-dialog__title">
                Edit User: {{ currentUser.email }}
            </v-card-title>

            <v-card-text>
                <h3>Scopes:</h3>
                <p>
                    (most common:
                    <code>
                        <a @click="addScope('user')">user</a>,
                        <a @click="addScope('admin')">admin</a>,
                        <a @click="addScope('private')">private</a>
                    </code>)
                </p>
                <scopes
                    :scopes="currentUser.scope"
                    @addScope="addScope"
                    @updateScope="updateScope"
                    @removeScope="removeScope"
                />
                <v-divider />
                <h3>Vendor:</h3>
                <vendors
                    :vendors="currentUser.vendor"
                    @addVendor="addVendor"
                    @updateVendor="updateVendor"
                    @removeVendor="removeVendor"
                />
            </v-card-text>

            <v-divider />

            <v-card-actions class="am-edit-user-dialog__buttons">
                <v-btn
                    color="primary"
                    text
                    @click.stop="emitSave"
                >
                    Save
                </v-btn>
                <v-btn
                    color="grey"
                    text
                    @click.stop="$emit('cancel')"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Vendors from './Vendors';
import Scopes from './Scopes';

export default {
    name: 'EditUserDialog',
    components: { Vendors, Scopes },
    model: {
        prop: 'show',
        event: 'updateShow'
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        user: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            showDialog: false,
            currentUser: {}
        };
    },
    watch: {
        show(val) {
            this.showDialog = val;
        },
        user(val) {
            this.currentUser = { ...(val || {}) };
        }
    },
    methods: {
        addVendor(vendor) {
            vendor = vendor ? vendor.trim() : vendor;
            if (!vendor) return;
            if (this.currentUser.vendor.indexOf(vendor) === -1) {
                this.currentUser.vendor.push(vendor);
            }
        },

        updateVendor(index, vendor) {
            vendor = vendor ? vendor.trim() : vendor;
            if (!vendor) return;
            this.$set(this.currentUser.vendor, index, vendor);
        },

        removeVendor(index) {
            this.$delete(this.currentUser.vendor, index);
        },

        addScope(scope) {
            scope = scope ? scope.trim() : scope;
            if (!scope) return;
            if (this.currentUser.scope.indexOf(scope) === -1) {
                this.currentUser.scope.push(scope);
            }
        },

        updateScope(index, scope) {
            scope = scope ? scope.trim() : scope;
            if (!scope) return;
            this.$set(this.currentUser.scope, index, scope);
        },

        removeScope(index) {
            this.$delete(this.currentUser.scope, index);
        },

        emitSave() {
            const nonEmptyVendors = this.currentUser.vendor.filter(input => input !== '');
            const nonEmptyScopes = this.currentUser.scope.filter(input => input !== '');
            const payload = {
                id: this.currentUser.id,
                payload: {
                    scope: nonEmptyScopes,
                    vendor: nonEmptyVendors
                }
            };
            this.$emit('save', payload);
        }
    }
};
</script>

<style lang="scss" scoped>

  .am-edit-user-dialog {
    &__title {
      font-weight: bold;
    }
  }

</style>
