import axios from 'axios';
import AbstractProvider from '../AbstractProvider';
import uploadUtils from '../../../utils/upload';
import config from '../../../utils/config';

export default class AWSProvider extends AbstractProvider {
    constructor() {
        super();
        this.url = config.AWS_BASE_URL;
    }

    /**
     * @abstract
     */
    async getModules() {
        const { data } = await axios.get(`${this.url}/modules`, { params: { latest: true } });

        const latest = Object.entries(data).reduce((acc, [moduleName, versions]) => {
            const version = Object.keys(versions)[0];
            acc.push({ name: moduleName, ...versions[version], bundleVersion: version });
            return acc;
        }, []);

        const moduleKeys = latest
            .filter(module => module.name.split('.').length === 3)
            .map(module => module.name);

        return latest.filter(module => {
            if (module.name.split('.').length === 3) {
                return true;
            }
            // Only include if service has at least one module
            const hasModule = moduleKeys.findIndex(m => {
                return m.includes(module.name);
            });
            return hasModule === -1;
        });
    }

    async getModule(moduleName, version) {
        const { data } = await axios.get(`${this.url}/modules/${moduleName}`, { params: { version } });
        return data;
    }

    /**
     * @abstract
     */
    async getComponents(module, version) {
        const { data } = await axios.get(`${this.url}/modules/${module}/components`, {
            params: { version }
        });
        return Object.entries(data).reduce((acc, [componentName, data]) => {
            acc.push({ name: componentName, ...data });
            return acc;
        }, []);
    }

    async download(name) {
        const filename = `${name}.zip`;
        const requestDownloadUrl = `${this.url}/modules/download/${filename}`;
        const { data: downloadUrl } = await axios.get(requestDownloadUrl);

        const link = document.createElement('a');
        link.href = downloadUrl.url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    async install(name) {
        const filename = `${name}.zip`;
        const requestDownloadUrl = `${this.url}/modules/download/${filename}`;
        const { data: downloadUrl } = await axios.get(requestDownloadUrl);

        const response = await axios.get(downloadUrl.url, {
            responseType: 'blob'
        });
        const file = new File([response.data], `${filename}`);
        return uploadUtils.uploadModule(file);
    }
}
