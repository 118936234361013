<template>
    <v-alert
        :value="alert.properties.value"
        :type="alert.properties.type"
        dismissible
    >
        Currently, all your end-users have access to all connectors.
        If that is not intended, click <a @click="$emit('fix')">here</a> to go to the ACL section and change it.
    </v-alert>
</template>

<script>
export default {
    name: 'UserACLAlert',

    props: {
        alert: {
            type: Object,
            required: true
        }
    }
};
</script>

<style scoped>

</style>
