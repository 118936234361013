<template>
    <v-container
        fluid
        grid-list-xl
    >
        <v-layout
            justify-center
            wrap
        >
            <v-flex
                md12
            >
                <material-card
                    :title="'ACL Types'"
                    color="tertiary"
                >
                    <v-data-table
                        :headers="headers"
                        :items="aclTypes"
                        :loading-data="loading"
                        hide-actions
                    >
                        <template
                            slot="headerCell"
                            slot-scope="{ header }"
                        >
                            <span
                                class="subheading font-weight-bold text--darken-3"
                                v-text="header.text"
                            />
                        </template>

                        <template
                            slot="items"
                            slot-scope="{ item }"
                        >
                            <td>{{ item }}</td>
                            <td>
                                <v-icon
                                    class="edit-icon action-icon"
                                    @click="viewType(item)"
                                >
                                    mdi-view-sequential
                                </v-icon>
                            </td>
                        </template>
                    </v-data-table>
                </material-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import AclRequests from '../../utils/requests/acl';
export default {
    name: 'AclTypes',
    metaInfo() {
        return {
            title: 'Appmixer Backoffice - ACL'
        };
    },
    filters: {
        aclCount(aclArray) {
            return aclArray.length;
        },
        formatAttributes(attributeArray) {
            return attributeArray.join(', ');
        }
    },
    data() {
        return {
            headers: [
                {
                    sortable: false,
                    text: 'Type',
                    value: 'type'
                },
                {
                    sortable: false,
                    text: '',
                    value: 'actions'
                }
            ],
            loading: false,
            aclTypes: []
        };
    },
    created() {
        this.getTypes();
    },
    methods: {
        async getTypes() {
            this.loading = true;

            try {
                this.aclTypes = await AclRequests.getTypes();
            } finally {
                this.loading = false;
            }
        },
        async viewType(type) {
            this.$router.push({ path: `/dashboard/acl/${type}` });
        }
    }
};
</script>

<style lang="scss" scoped>
    .edit-icon {
        cursor: pointer;
    }
    .justify-right {
        justify-content: flex-end;
    }
</style>
