<template>
    <div>
        <user-acl-alert
            :alert="userAlert"
            class="modules__alert"
            @fix="goToACL"
        />

        <v-alert
            v-bind="alert.properties"
            class="module-details__alert"
            dismissible
        >
            {{ alert.properties.message }}
        </v-alert>

        <v-alert
            :value="!loaders.checkingDocs
                && currentModule.installed
                && currentModule.hasAuth
                && !hasConfig
                && docsLink"
            type="warning"
            class="modules__alert"
            dismissible
        >
            This connector uses authentication but it may not be configured properly. Checkout the
            <a
                :href="docsLink"
                target="_blank"
            >documentation</a> to make sure it is configured properly.
        </v-alert>

        <v-alert
            :value="!loaders.checkingDocs
                && currentModule.installed
                && currentModule.hasAuth
                && !hasConfig
                && !docsLink"
            type="info"
            class="module-details__alert module-details__alert-info"
            dismissible
        >
            If the connector uses OAuth for user authentication or requires other configuration, you must configure the
            connector before it can be used.
            Go to the <a href="/dashboard/service-config">Connectors Configuration</a> page to configure your connector.
        </v-alert>

        <v-alert
            :value="!loaders.checkingDocs
                && currentModule.installed
                && hasValidationErrors"
            type="error"
            class="modules__alert"
            dismissible
        >
            This connector is not configured properly. Click
            <a
                class="module-details__config-link"
                @click="goToConfiguration"
            >here</a> to go
            to the configuration section and solve the configuration issues.
        </v-alert>

        <v-container
            fluid
            grid-list-xl
        >
            <v-layout
                justify-center
                wrap
            >
                <confirm-dialog
                    :show="modals.postInstall.properties.show"
                >
                    <template #header>
                        Connector installed
                    </template>
                    <template #body>
                        The connector has been installed in your system. However, right now it is available to users
                        with admin privileges only. You can make the connector available to testers or others in the
                        permissions section. Remember to make the required configurations to your connector before
                        making it public for your users.
                    </template>
                    <template #action-buttons>
                        <v-btn
                            color="primary"
                            text
                            @click.stop="modals.postInstall.controller.closeDialog()"
                        >
                            Ok
                        </v-btn>
                    </template>
                </confirm-dialog>

                <confirm-dialog-loader
                    v-bind="modals.confirmDialogLoader.properties"
                    @cancel="modals.confirmDialogLoader.controller.closeDialog"
                />

                <module-upload-modal
                    :show="uploadModal.show"
                    @close="uploadModal.show = false"
                />

                <breaking-update-modal
                    :show="breakingUpdate.show"
                    :stop-flows="breakingUpdate.stopFlows"
                    :uploading="breakingUpdate.uploading"
                    @confirm="updateBreakingChange"
                    @cancel-stop-flows="cancelStopFlows"
                    @cancel="breakingUpdate.show = false"
                />

                <v-flex xs12>
                    <span
                        class="pointer"
                        @click="goToModules"
                    >
                        <v-icon class="module-details__back-icon">mdi-arrow-left-bold</v-icon>
                        <span class="ml-2">Back to connectors</span>
                    </span>
                </v-flex>

                <v-flex
                    xs12
                    md6
                >
                    <v-layout
                        v-if="loadingView"
                        class="mt-5"
                        justify-center
                    >
                        <v-progress-circular
                            :size="40"
                            color="primary"
                            indeterminate
                        />
                    </v-layout>

                    <div
                        v-else
                        class="module-details__header"
                    >
                        <div class="module-details__thumbnail">
                            <img
                                :src="currentModule.icon"
                                class="module-details__icon"
                                alt=""
                            >
                        </div>

                        <div class="module-details__title">
                            <div class="module-details__label-container">
                                <span
                                    class="module-details__label"
                                    v-text="currentModule.label"
                                />

                                <v-chip
                                    v-if="currentModule.installed"
                                    class="modules-list__module-installed"
                                    color="tertiary"
                                    text-color="white"
                                >
                                    Installed (v{{ currentModule.bundleVersion }})
                                </v-chip>

                                <v-chip
                                    v-if="currentModule.installed && hasNewVersion(currentModule)"
                                    class="modules-list__module-installed"
                                    color="success"
                                    text-color="white"
                                >
                                    Update available (v{{ latestEngineCompatibleVersion }})
                                </v-chip>
                            </div>

                            <span
                                class="module-details__name"
                                v-text="`[${currentModule.name}]`"
                            />
                            <div
                                class="module-details__description"
                                v-text="currentModule.description"
                            />
                        </div>
                    </div>
                </v-flex>

                <v-flex
                    xs12
                    md4
                    offset-md2
                >
                    <div class="module-details__label">
                        Usage statistics:
                    </div>

                    <div class="module-details__description">
                        <div
                            v-if="loaders.metrics"
                            class="module-details__metrics-loader"
                        >
                            <v-progress-circular
                                :size="20"
                                class="ml-3"
                                color="primary"
                                indeterminate
                            />
                        </div>

                        <template v-else>
                            <div v-if="!metrics.index.ready">
                                The component usage index have not been built yet
                                <v-btn
                                    color="primary"
                                    text
                                    @click="buildIndex"
                                >
                                    Build Index
                                </v-btn>
                            </div>

                            <template v-else>
                                <div class="mt-3">
                                    Used in flows:&nbsp;
                                    <span
                                        class="module-details__metric-number"
                                        @click="onMetricClick('flows')"
                                    >
                                        {{ metrics.usedInFlows }}
                                    </span>
                                </div>

                                <div class="mt-2">
                                    Used in running flows:&nbsp;
                                    <span
                                        class="module-details__metric-number"
                                        @click="onMetricClick('flows', 'running')"
                                    >
                                        {{ metrics.usedInRunningFlows }}
                                    </span>
                                </div>

                                <div class="mt-2">
                                    Used by users:&nbsp;
                                    <span
                                        class="module-details__metric-number"
                                        @click="onMetricClick('users')"
                                    >
                                        {{ metrics.usedByUsers }}
                                    </span>
                                </div>

                                <div class="mt-2">
                                    Used by users in running flows:&nbsp;
                                    <span
                                        class="module-details__metric-number"
                                        @click="onMetricClick('users', 'running')"
                                    >
                                        {{ metrics.usedByUsersInRunningFlows }}
                                    </span>
                                </div>
                            </template>
                        </template>
                    </div>
                </v-flex>

                <v-flex
                    v-if="!loadingView"
                    xs12
                >
                    <div
                        v-if="!currentModule.installed && !latestEngineCompatibleVersion"
                        class="mb-3 module-details__incompatible-message"
                    >
                        Your current engine version is not compatible with the required version of this connector.
                        <br><br>
                        Current engine version: {{ $store.state.engine.version }}
                        <br>
                        Required connector engine version: {{ currentModule.engine }}
                    </div>

                    <div
                        v-if="hasNewVersion(currentModule) && !isEngineCompatible"
                        class="mb-3 module-details__incompatible-message"
                    >
                        There is a new available version of this connector. However, your current engine version is not
                        compatible with its required engine version.
                        <br><br>
                        Current engine version: {{ $store.state.engine.version }}
                        <br>
                        New connector version required engine version: {{ currentModule.engine }}
                    </div>

                    <v-btn
                        v-if="!loaders.downloadingModule"
                        color="primary"
                        text
                        @click="() => downloadModule(currentModule)"
                    >
                        Download connector
                    </v-btn>

                    <v-btn
                        v-if="!currentModule.installed && !loaders.installingModule && latestEngineCompatibleVersion"
                        color="primary"
                        text
                        @click="() => installModule(currentModule)"
                    >
                        Install connector
                    </v-btn>

                    <template v-if="currentModule.installed && !loaders.installingModule && !loaders.acl">
                        <v-btn
                            color="primary"
                            text
                            @click="showDeleteModuleModal(currentModule.name)"
                        >
                            Remove connector
                        </v-btn>

                        <v-btn
                            v-if="hasNewVersion(currentModule) && isEngineCompatible"
                            color="primary"
                            text
                            @click="updateProcess"
                        >
                            Update connector
                        </v-btn>
                    </template>

                    <div v-if="loaders.installingModule">
                        <span>Installing connector. This can take a few moments...</span>
                        <v-progress-circular
                            :size="20"
                            class="ml-3"
                            color="primary"
                            indeterminate
                        />
                    </div>

                    <div v-if="loaders.downloadingModule">
                        <span>Downloading connector. This can take a few moments...</span>
                        <v-progress-circular
                            :size="20"
                            class="ml-3"
                            color="primary"
                            indeterminate
                        />
                    </div>

                    <div v-if="loaders.publish">
                        <span>Changing connector permissions...</span>
                        <v-progress-circular
                            :size="20"
                            class="ml-3"
                            color="primary"
                            indeterminate
                        />
                    </div>
                </v-flex>

                <v-flex
                    xs12
                >
                    <div class="module-details__label">
                        Documentation
                    </div>
                    <div
                        v-if="loaders.checkingDocs"
                        class="mt-4"
                    >
                        <span>Checking documentation...</span>
                        <v-progress-circular
                            :size="20"
                            class="ml-3"
                            color="primary"
                            indeterminate
                        />
                    </div>

                    <div
                        v-if="docsLink"
                        class="mt-4"
                    >
                        A documentation for this connector can be found
                        <a
                            :href="docsLink"
                            target="_blank"
                        >at this link</a>.
                    </div>
                    <div
                        v-if="!loaders.checkingDocs && !docsLink"
                        class="mt-4"
                    >
                        There is no documentation for this connector.
                    </div>
                </v-flex>

                <v-flex
                    v-if="currentModule.installed"
                    xs12
                >
                    <div class="module-details__label">
                        Access Control
                    </div>

                    <div
                        v-if="!isPublished"
                        class="mt-4"
                    >
                        The connector is not available to users (Scope <i>user</i>). This could be intended when, e.g.
                        the connector is not yet configured. However, if you want to add the ACL rule which makes the
                        connector visible to users, click the next button.
                        <br>
                        <v-btn
                            color="primary"
                            text
                            @click="publish"
                        >
                            Add ACL rule for users
                        </v-btn>
                    </div>

                    <div class="mt-4">
                        Here is a list of the ACL rules that apply to this connector. You can edit the rules in the
                        <a @click="goToACL">ACL section</a>. Find more information about ACL in the
                        <a
                            href="https://docs.appmixer.com/appmixer/tutorials/setting-acl"
                            target="_blank"
                        >documentation</a>.
                    </div>

                    <acl-rules
                        :rules="moduleACL"
                        class="mt-4"
                    />
                </v-flex>

                <v-flex
                    v-if="hasNewVersion(currentModule)"
                    xs12
                    class="mt-4"
                >
                    <div class="module-details__label">
                        Changelog
                    </div>

                    <div class="mt-4 ml-3">
                        <div v-if="!Object.keys(currentModule.changelog).length">
                            No changelog available
                        </div>

                        <div
                            v-for="(value, key) in displayChangelog"
                            v-else
                            :key="key"
                        >
                            <h3>{{ key }}</h3>

                            <ul>
                                <li
                                    v-for="(change, idx) in value"
                                    :key="idx"
                                >
                                    {{ change }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </v-flex>

                <v-flex
                    xs12
                    class="mt-5"
                >
                    <components-list
                        v-if="!loadingView"
                        :components="currentModuleComponents"
                    />
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import ACL from '../../../services/ACL';
import AppsQueries from '../../../utils/requests/apps';
import IndexQueries from '../../../utils/requests/dataIndex';
import FlowsQueries from '../../../utils/requests/flow';
import ComponentQueries from '../../../utils/requests/component';
import ModulesProvider from '../../../services/ModulesProvider';
import ModulesMixin from '../../../mixins/views/modules/modules';
import AlertMixin from '../../../mixins/alert';
import ConfirmDialogMixin from '../../../mixins/confirmDialog';
import ACLUtilsMixin from '../../../mixins/aclUtils';
import ObjectUtils from '../../../utils/object';
import UploadStatus from '../utils/UploadStatus';
import _ from 'lodash';
import { compare, satisfies, compareVersions } from 'compare-versions';
import ConfirmDialog from '../../../components/common/ConfirmDialog/Basic';
import ConfirmDialogLoader from '../../../components/common/ConfirmDialog/WithLoader';
import ModuleUploadModal from '../upload-module/Modal';
import FileSelect from '../../../components/common/File/Select';
import ComponentsList from './ComponentsList';
import AclRules from './ACLRules';
import BreakingUpdateModal from '../BreakingUpdateModal';
import Poll from '../../../utils/poll';
import DocsRequests from '../../../utils/requests/docs';
import ValidateModule from '../utils/ValidateModule';
import AuthConfigRequests from '../../../utils/requests/authConfig';
import UserACLAlert from '@/views/modules/UserACLAlert';
import aclUtils from '../../../utils/acl';

export default {
    name: 'ModuleDetailsView',

    components: {
        UserACLAlert,
        BreakingUpdateModal,
        AclRules,
        ConfirmDialog,
        ConfirmDialogLoader,
        ModuleUploadModal,
        FileSelect,
        ComponentsList,
        UserAclAlert: UserACLAlert
    },

    mixins: [
        ModulesMixin,
        AlertMixin,
        ConfirmDialogMixin,
        ACLUtilsMixin
    ],

    props: {
        module: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            alert: this.createAlert(),
            userAlert: this.createAlert(),
            configAlert: this.createAlert(),
            bundleConfigData: {
                schema: {},
                values: {},
                errors: []
            },
            serviceId: '',
            modals: {
                confirmDialogLoader: this.createConfirmDialogLoader(),
                postInstall: this.createConfirmDialog(),
                deleteModule: this.createConfirmDialogLoader()
            },
            breakingUpdate: {
                show: false,
                stopFlows: {
                    loading: false,
                    ticket: null,
                    status: '',
                    progress: {
                        stepsDone: 0,
                        stepsTotal: 0
                    }
                },
                uploading: {
                    loading: false
                }
            },
            actionLoaders: {
                installing: false,
                downloading: false,
                permissions: false,
                checkingDocs: false
            },
            metrics: {
                index: {
                    loading: false,
                    ready: false
                },
                usedInFlows: 0,
                usedInRunningFlows: 0,
                usedByUsers: 0,
                usedByUsersInRunningFlows: 0
            },
            uploadModal: {
                show: false
            },
            aclRules: [],
            docsLink: null,
            currentModuleVersions: {},
            viewLoader: false
        };
    },

    computed: {
        loaders() {
            const loaders = this.$store.getters['modules/loaders'];
            return {
                modules: loaders.apps.available || loaders.apps.installed,
                components: loaders.components.available || loaders.components.installed,
                installingModule: this.actionLoaders.installing,
                downloadingModule: this.actionLoaders.downloading,
                checkingDocs: this.actionLoaders.checkingDocs,
                metrics: this.metrics.index.loading,
                publish: this.actionLoaders.publish
            };
        },

        loadingView() {
            // Ensure engine version is loaded before showing content
            return !this.$store.state.engine.version ||
                this.loaders.modules ||
                this.loaders.components ||
                this.viewLoader;
        },

        moduleObject() {
            const modules = this.$store.getters['modules/modules'] || [];
            const installed = modules.find(m => m.name === this.module) || {};
            if (installed) {
                return installed;
            }

            if (this.latestEngineCompatibleVersion) {
                const available = this.currentModuleVersions[this.latestEngineCompatibleVersion];
                return {
                    ...available,
                    bundleVersion: this.latestEngineCompatibleVersion,
                    installed: false
                };
            }

            return null;
        },

        currentModuleComponents() {
            const components = this.$store.getters['modules/components'];
            return components.filter(c => c.name.startsWith(this.module));
        },

        moduleACL() {
            return this.$store.state.permissions.acl.filter(rule => {
                return aclUtils.ruleCanUseModule(this.module, rule);
            });
        },

        currentModule() {
            const hasAuth = this.currentModuleComponents.some(c => c.auth);

            let changelog;

            if (this.orderedAvailableVersions.length) {
                const latestVersion = this.orderedAvailableVersions[0];
                changelog = this.normalizeChangelog(this.currentModuleVersions[latestVersion].changelog || {});
            }

            return {
                ...this.moduleObject,
                available: Object.keys(this.currentModuleVersions).length > 0,
                availableVersions: this.currentModuleVersions,
                changelog,
                hasAuth
            };
        },

        orderedAvailableVersions() {
            // First the newest, last the older
            if (!Object.keys(this.currentModuleVersions).length) {
                return [];
            }
            return Object.keys(this.currentModuleVersions).sort(compareVersions).reverse();
        },

        isEngineCompatible() {
            // If there is no specified version in the bundle, allow it
            if (!this.currentModule.engine) {
                return true;
            }

            const engineVersion = this.$store.state.engine.version;

            return satisfies(engineVersion, this.currentModule.engine);
        },

        latestEngineCompatibleVersion() {
            const engineVersion = this.$store.state.engine.version;
            for (const version of this.orderedAvailableVersions) {
                const bundleRequiredEngineVersion = this.currentModuleVersions[version].engine;
                // If the bundle doesn't specify a version, we assume is compatible with anything
                if (!bundleRequiredEngineVersion) {
                    return version;
                }
                if (satisfies(engineVersion, bundleRequiredEngineVersion)) {
                    return version;
                }
            }
            return null;
        },

        displayChangelog() {
            const changelog = this.currentModule.changelog;

            const versions = Object.keys(changelog)
                .filter(version => compare(version, this.currentModule.bundleVersion, '>'))
                .sort((a, b) => compare(a, b, '>') ? -1 : 1);

            return versions.reduce((acc, ver) => {
                acc[ver] = changelog[ver];
                return acc;
            }, {});
        },

        isPublished() {
            const acl = new ACL(this.moduleACL);
            return acl.can('user', 'use', this.module);
        },

        hasConfig() {
            return !!Object.keys(this.bundleConfigData.values).length;
        },

        hasValidationErrors() {
            return !!this.bundleConfigData.errors.length;
        }
    },

    async created() {
        this.viewLoader = true;
        await this.checkIndex();
        this.loadUsageMetrics();

        try {
            await Promise.all([
                this.$store.dispatch('modules/loadInstalledModules'),
                await this.loadCurrentModuleData()
            ]);

            if (this.currentModule.installed) {
                await this.loadBundleConfigData();
            }

            if (ObjectUtils.isEmpty(this.moduleObject)) {
                this.$router.replace('/dashboard/modules');
            }

            await this.$store.dispatch('permissions/initializeACL');

            await this.checkUserPermission(this.userAlert.controller);
            await this.getDocsLink(this.module);

            await this.loadComponentData(this.module, this.latestEngineCompatibleVersion);
        } finally {
            this.viewLoader = false;
        }

        const { status } = await UploadStatus.status(this.module);
        if (status === UploadStatus.constants.UPLOADING) {
            this.toggleInstallLoader(true);
            try {
                await UploadStatus.waitForUpload(this.module);
            } catch (err) {
                this.alert.controller.showAlert(
                    'error', `An error occurred during connector installation: ${err.message || err}`
                );
            }
            try {
                await Promise.all([
                    this.$store.dispatch('modules/loadInstalledModules', { withLoaders: false }),
                    this.$store.dispatch('modules/loadInstalledComponents', { withLoaders: false })
                ]);
            } catch (err) {
                this.alert.controller.showAlert(
                    'error', `An error occurred while loading the connector: ${err.message || err}`
                );
            }
            this.toggleInstallLoader(false);
        }
    },

    methods: {
        async loadCurrentModuleData() {
            const provider = ModulesProvider.create();
            this.currentModuleVersions = await provider.getModule(this.module);
        },

        async loadBundleConfigData() {
            const moduleParts = this.module.split('.');
            let configLink = this.module;
            let validationResult = await ValidateModule.validateConfig(this.module);

            const hasConfigSchema = Object.keys(validationResult.configurationSchema || {}).length;

            // If no configuration was found on module.json, and this has module level, we'll try getting the values
            // from service.json.
            if (!hasConfigSchema && moduleParts.length === 3) {
                const service = `${moduleParts[0]}.${moduleParts[1]}`;
                configLink = service;
                validationResult = await ValidateModule.validateConfig(service);
            }

            this.bundleConfigData = {
                schema: validationResult.configurationSchema || {},
                values: validationResult.configurationValues || {},
                errors: validationResult.errors || [],
                configLink
            };
        },

        normalizeChangelog(changelog) {
            if (Array.isArray(changelog)) {
                const initial = changelog[0];
                const otherVersions = changelog[1] || {};

                return {
                    '1.0.0': [
                        initial
                    ],
                    ...otherVersions
                };
            }

            return changelog;
        },

        async checkIndex() {
            try {
                this.metrics.index.loading = true;
                const response = await IndexQueries.queryComponentUsage({
                    count: true
                });

                const count = _.get(response, '[0].count', 0);

                this.metrics.index.ready = count !== 0;
            } finally {
                this.metrics.index.loading = false;
            }
        },

        async buildIndex() {
            try {
                this.metrics.index.loading = true;
                await IndexQueries.buildComponentUsageIndex();
                this.metrics.index.ready = true;
                await this.loadUsageMetrics();
            } finally {
                this.metrics.index.loading = false;
            }
        },

        goToModules() {
            this.$router.push({ path: '/dashboard/modules' });
        },

        goToConfiguration() {
            this.$router.push(`/dashboard/service-config/${this.bundleConfigData.configLink}`);
        },

        getServiceConfigLink() {
            const [vendor, service] = this.currentModule.name.split('.');
            const serviceId = `${vendor}:${service}`;
            this.$router.push(`/dashboard/service-config/${serviceId}`);
        },

        goToACL() {
            this.$router.push({ path: '/dashboard/acl/components' });
        },

        onMetricClick(entity, stage) {
            const query = { 'module-query': this.currentModule.name + (stage ? `:${stage}` : '') };
            let path;
            if (entity === 'flows') {
                path = '/dashboard/flows';
            } else {
                path = '/dashboard/users';
            }
            this.$router.push({ path, query });
        },

        async loadUsageMetrics() {
            const query = (group, stage = null) => {
                return IndexQueries.queryComponentUsage({
                    componentType: this.module,
                    ...(stage ? { stage } : {}),
                    group,
                    count: true
                });
            };

            const [allFlows, runningFlows, allUsers, runningUsers] = await Promise.all([
                query('$flowId'),
                query('$flowId', 'running'),
                query('$userId'),
                query('$userId', 'running')
            ]);

            this.metrics.usedInFlows = _.get(allFlows, '[0].count', 0);
            this.metrics.usedInRunningFlows = _.get(runningFlows, '[0].count', 0);
            this.metrics.usedByUsers = _.get(allUsers, '[0].count', 0);
            this.metrics.usedByUsersInRunningFlows = _.get(runningUsers, '[0].count', 0);
        },

        async downloadModule(module) {
            this.alert.controller.closeAlert();
            this.actionLoaders.downloading = true;
            try {
                if (module.installed) {
                    try {
                        await this.downloadFromSystem(module.name);
                    } catch (e) {
                        if (_.get(e, 'response.status') !== 404) {
                            throw e;
                        }
                        const provider = ModulesProvider.create();
                        await provider.download(`${module.name}-${this.latestEngineCompatibleVersion}`);
                    }
                } else {
                    const provider = ModulesProvider.create();
                    await provider.download(`${module.name}-${this.latestEngineCompatibleVersion}`);
                }
            } catch (err) {
                this.alert.controller.showAlert(
                    'error', `An error occurred during downloading the connector: ${err.message || err}`
                );
            } finally {
                this.actionLoaders.downloading = false;
            }
        },

        async installModule(module) {
            this.alert.controller.closeAlert();
            this.toggleInstallLoader(true);
            try {
                const provider = ModulesProvider.create();
                const { ticket } = await provider.install(`${module.name}-${this.latestEngineCompatibleVersion}`);
                UploadStatus.saveTicket(module.name, ticket);
                await UploadStatus.waitForUploadByTicket(ticket);
                await Promise.all([
                    this.$store.dispatch('modules/loadInstalledModules', { withLoaders: false }),
                    this.$store.dispatch('modules/loadInstalledComponents', { withLoaders: false })
                ]);

                await this.loadBundleConfigData();

                const { properties = {} } = this.bundleConfigData.schema || {};

                const defaults = Object.entries(properties).reduce((acc, [key, value]) => {
                    if (value.default) {
                        acc[key] = value.default;
                    }
                    return acc;
                }, {});

                if (Object.keys(defaults).length) {
                    const updatedConfig = {
                        ...defaults,
                        ...this.bundleConfigData.values
                    };

                    const queryBundle = this.module.replace(/\./g, ':');
                    await AuthConfigRequests.updateAuthConfig(queryBundle, updatedConfig);
                }

                this.alert.controller.showAlert('success', 'Connector installed successfully');

                // Show post installation dialog
                this.modals.postInstall.controller.showDialog();
            } catch (err) {
                this.alert.controller.showAlert(
                    'error', `An error occurred during connector installation: ${err.message || err}`
                );
            } finally {
                this.toggleInstallLoader(false);
            }
        },

        updateProcess() {
            const { bundleVersion } = this.currentModule;
            const isBreakingChange = !satisfies(this.latestEngineCompatibleVersion, `^${bundleVersion}`);
            if (isBreakingChange) {
                // Show breaking modal
                this.breakingUpdate.show = true;
            } else {
                this.updateModule();
            }
        },

        async updateBreakingChange() {
            this.alert.controller.closeAlert();

            this.breakingUpdate.stopFlows.loading = true;

            const { ticket } = await FlowsQueries.stopFlowsByModule(this.currentModule.name);
            this.breakingUpdate.stopFlows.ticket = ticket;

            const statusFn = async () => {
                const response = await FlowsQueries.stopFlowsStatus(ticket);
                this.breakingUpdate.stopFlows.status = response.status;
                return response;
            };
            const successCb = (status) => {
                return status.status === 'completed' || status.status === 'cancelled';
            };
            const errorCb = (status) => {
                return status.err;
            };

            const poll = new Poll(statusFn, successCb, errorCb);
            poll.onStatus(status => {
                this.breakingUpdate.stopFlows.progress = { stepsDone: status.stepsDone, stepsTotal: status.stepsTotal };
            });

            poll.start();

            await poll.waitForCompletion();

            this.breakingUpdate.stopFlows.loading = false;
            this.breakingUpdate.stopFlows.ticket = null;
            this.breakingUpdate.stopFlows.progress = { stepsDone: 0, stepsTotal: 0 };
            this.breakingUpdate.show = false;

            if (this.breakingUpdate.stopFlows.status === 'completed') {
                await this.loadUsageMetrics();
                await this.updateModule();
            }
        },

        async updateModule() {
            this.alert.controller.closeAlert();
            this.toggleInstallLoader(true);
            try {
                const previousSchema = _.cloneDeep(this.bundleConfigData.schema?.properties || {});

                const provider = ModulesProvider.create();
                const packageName = `${this.currentModule.name}-${this.latestEngineCompatibleVersion}`;

                const { ticket } = await provider.install(packageName);
                UploadStatus.saveTicket(this.currentModule.name, ticket);
                await UploadStatus.waitForUploadByTicket(ticket);
                await Promise.all([
                    this.$store.dispatch('modules/loadInstalledModules', { withLoaders: false }),
                    this.$store.dispatch('modules/loadInstalledComponents', { withLoaders: false })
                ]);

                await this.loadBundleConfigData();

                const previousKeys = Object.keys(previousSchema);
                const currentKeys = Object.keys(this.bundleConfigData.schema?.properties || {});

                const newKeys = _.difference(currentKeys, previousKeys);

                const defaults = newKeys.reduce((acc, key) => {
                    const value = this.bundleConfigData.schema.properties[key];
                    if (value.default) {
                        acc[key] = value.default;
                    }
                    return acc;
                }, {});

                if (Object.keys(defaults).length) {
                    const updatedConfig = {
                        ...defaults,
                        ...this.bundleConfigData.values
                    };

                    const queryBundle = this.module.replace(/\./g, ':');
                    await AuthConfigRequests.updateAuthConfig(queryBundle, updatedConfig);
                }

                this.alert.controller.showAlert('success', 'Connector updated successfully');
            } catch (err) {
                this.alert.controller.showAlert(
                    'error', `An error occurred during connector installation: ${err.message || err}`
                );
            } finally {
                this.toggleInstallLoader(false);
            }
        },

        async cancelStopFlows() {
            const { status } = await FlowsQueries.cancelStopFlows(this.breakingUpdate.stopFlows.ticket);
            if (status === 'ok') {
                this.breakingUpdate.stopFlows.loading = false;
                this.breakingUpdate.stopFlows.ticket = null;
                this.breakingUpdate.stopFlows.progress = { stepsDone: 0, stepsTotal: 0 };
                this.breakingUpdate.show = false;
            }
        },

        toggleInstallLoader(loading) {
            this.actionLoaders.installing = loading;
        },

        toggleModuleLoaders(loading) {
            this.$store.commit('modules/setAvailableModulesState', { loading });
            this.$store.commit('modules/setInstalledModulesState', { loading });
        },

        async removeModule(name) {
            this.toggleModuleLoaders(true);
            this.modals.confirmDialogLoader.controller.setLoading(true);
            this.alert.controller.closeAlert();

            await AppsQueries.delete(name);
            await this.$store.dispatch('modules/loadModuleData');

            this.modals.confirmDialogLoader.controller.setLoading(false);
            this.toggleModuleLoaders(false);
            this.modals.confirmDialogLoader.controller.closeDialog(false);

            // If the module was custom, then it will not be longer listed
            if (ObjectUtils.isEmpty(this.moduleObject)) {
                this.$router.replace('/dashboard/modules');
            }
        },

        async downloadFromSystem(name) {
            this.alert.controller.closeAlert();

            const response = await ComponentQueries.download(name);

            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        },

        hasNewVersion(module) {
            if (this.latestEngineCompatibleVersion) {
                return compare(module.bundleVersion, this.latestEngineCompatibleVersion, '<');
            }
            return false;
        },

        showDeleteModuleModal(name) {
            const title = 'Confirm action';
            const body = 'Are you sure you want to delete the connector? This action cannot be undone.';
            const callback = () => this.removeModule(name);
            this.modals.confirmDialogLoader.controller.showDialog(title, body, callback);
        },

        checkUserPermission(alertController) {
            if (this.hasUserRule()) {
                alertController.showAlert('warning');
            } else {
                alertController.closeAlert();
            }
        },

        async getDocsLink(module) {
            this.$set(this.actionLoaders, 'checkingDocs', true);
            try {
                this.docsLink = await DocsRequests.getDocsLink(module);
            } finally {
                this.$set(this.actionLoaders, 'checkingDocs', false);
            }
        },

        async publish() {
            const newRule = {
                role: 'user',
                resource: `${this.module}*`,
                action: ['*'],
                attributes: ['non-private']
            };
            await this.$store.dispatch('permissions/addRule', { rule: newRule });
            await this.$store.dispatch('permissions/initializeACL');
        }
    }
};
</script>

<style lang="scss" scoped>
.module-details {
  &__alert {
    position: sticky;
    top: 0;
  }

  &__alert-info {
    background-color: #1565c0 !important;
  }

  &__back-icon {
    line-height: 20px;
  }

  &__header {
    display: flex;
    align-items: flex-start;
  }

  &__thumbnail {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 84px;
    height: 84px;
    border-radius: 100%;
    background: white;
  }

  &__icon {
    display: block;
    max-height: 36px;
    max-width: 36px;
  }

  &__title {
    margin-top: 28px;
    margin-left: 24px;
  }

  &__label-container {
    display: flex;
    align-items: center;
  }

  &__label {
    font: 24px 'nunitosans-semibold';
    color: #222;
    margin-right: 10px;
  }

  &__description {
    max-width: 335px;
    margin-top: 6px;
    font: 14px 'nunitosans-regular';
    line-height: 1.3em;
    color: #333;
  }

  &__incompatible-message {
    font: 14px 'nunitosans-regular';
    color: #dc3545
  }

  &__install-loader {
    display: flex;
    align-items: center;
  }

  &__metrics-loader {
    display: flex;
    justify-content: center;
  }

  &__metric-number {
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  }

  &__config-link {
    color: white;
    text-decoration: underline;
  }
}
.v-chip {
    border-radius: 4px;
}
</style>
